@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .navigation-link {
        @apply text-white hover:text-brandPrimary font-bold cursor-pointer text-base;
    }

    .navigation-link.active {
        @apply text-brandPrimary
    }

    .navigation-link-alt {
        @apply text-white hover:text-brandPrimary font-bold cursor-pointer text-base;
    }

    .navigation-link-alt.active {
        @apply text-brandDark
    }

    .btn-primary {
        @apply uppercase px-10 py-2 bg-brandPrimary text-white drop-shadow-xl hover:bg-brandDark cursor-pointer
    }

    .link-primary {
        @apply underline text-brandPrimary hover:text-brandDark cursor-pointer
    }

    .btn-secondary {
        @apply uppercase px-10 py-2 bg-transparent text-white border-white border-2 hover:bg-brandDark hover:scale-105 cursor-pointer
    }
}

.accordion-button:not(.collapsed) {
    background-color: #F3F3F3;
    color: black;
}

.hideMenuNav {
    display: none;
}

.showMenuNav {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #1A3D53;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.collapse {
 visibility: inherit;
}
